import classnames from 'classnames'

interface IBadgeProps {
  text: string
  className?: string
  marginBottomClass?: string
  maxWidthClass?: string
  hideRemoveBtn?: boolean
  onRemove?: Function
}

const Badge = ({
  text,
  className,
  marginBottomClass = 'mb-2',
  maxWidthClass = 'max-w-[180px]',
  hideRemoveBtn,
  onRemove,
}: IBadgeProps) => {
  return (
    <div
      style={{
        paddingTop: 4,
        paddingBottom: 4,
      }}
      className={classnames([
        'flex justify-center items-center rounded-md bg-oxford-gray-100 border border-[#B0BAC9] px-2 mr-2',
        marginBottomClass,
        maxWidthClass,
        className,
      ])}
    >
      <div className={'text-sm text-oxford-gray-800 leading-tight truncate'}>
        {text}
      </div>
      {!hideRemoveBtn && (
        <a className="flex items-center pl-2" onClick={(e) => onRemove(e)}>
          <i className="far fa-times text-sm leading-none"></i>
        </a>
      )}
    </div>
  )
}

export default Badge

import DropdownMenu from '@components/Ui/DropdownMenu'
import classNames from 'classnames'

interface IActionItem {
  id: any
  label: any
  action: Function
}

interface IContributorMoreActions {
  actionItems: IActionItem[]
  disabled?: boolean
}

const ContributorMoreActions = ({
  actionItems,
  disabled = false,
}: IContributorMoreActions) => {
  const getItems = () =>
    actionItems.map(({ id, label, action }, index) => ({
      id: id + index,
      label,
      onClick: (e) => {
        action(e)
      },
      disabled,
    }))

  return (
    <DropdownMenu
      renderLabel={() => (
        <i
          className={classNames([
            'far fa-ellipsis text-2xl px-3',
            {
              'text-primary': !disabled,
              'text-oxford-gray-300': disabled,
            },
          ])}
        />
      )}
      items={getItems()}
      popperPlacement="right"
    />
  )
}

export default ContributorMoreActions

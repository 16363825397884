import { MATERIALS_MANAGEMENT_PATHS } from './materialsManagementPaths'
import { REPORT_PATHS } from './reportPaths'

export const ROUTER_PATHS = {
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  PROJECT_DETAILS: '/projects/[id]',
  THREADS: '/threads',
  DYNAMIC_REMINDERS: '/dynamic-reminders',
  MY_TASKS: '/tasks',
  SEARCH: '/search',
  BOARDS: '/boards',
  TO_DO: '/todo',
  REPORTS: '/reports',
  PRODUCTION_PLANNING: '/production-planning',
  SIZE_MATRICES: '/size-matrices',
  COLLECTIONS: '/collections',
  MATERIALS_MANAGEMENT: '/materials-management',
  MATERIALS_DATABASE: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.MATERIALS_DATABASE}`,
  BILL_OF_MATERIALS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.BILL_OF_MATERIALS}`,
  ORDER_MATRIX: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.ORDER_MATRIX}`,
  MATERIAL_ORDERS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.MATERIAL_ORDERS}`,
  CREATE_ORDER: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.CREATE_ORDER}`,
  EDIT_ORDER: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.EDIT_ORDER}`,
  ORDER_DETAILS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.ORDER_DETAILS}`,
  STOCK_INVENTORY: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.STOCK_INVENTORY}`,
  TRANSACTIONS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.TRANSACTIONS}`,
  DOCUMENTS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.DOCUMENTS}`,
  MATERIAL_REQUESTS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.MATERIAL_REQUESTS}`,
  WAREHOUSES: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.WAREHOUSES}`,
  SUPPLIERS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.SUPPLIERS}`,
  MANUFACTURERS: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.MANUFACTURERS}`,
  ADDRESSES: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.ADDRESSES}`,
  MATERIAL_TYPES: `/materials-management/${MATERIALS_MANAGEMENT_PATHS.MATERIAL_TYPES}`,
  COLLECTION_DETAILS: '/collections/[id]',
  TASK_DETAILS: '/tasks/[id]',
  GOAL_DETAILS: '/goals/[id]',
  TODO_DETAILS: '/todo/[id]',
  BOARD_DETAILS: '/boards/[id]',
  AGGREGATED_REPORT: `/reports/${REPORT_PATHS.AGGREGATED}`,
  QUANTITY_AND_COST_REPORT: `/reports/${REPORT_PATHS.QUANTITY_AND_COST}`,
  ALL_TASKS_REPORT: `/reports/${REPORT_PATHS.ALL_TASKS}`,
  ALL_MILESTONES_REPORT: `/reports/${REPORT_PATHS.ALL_MILESTONES}`,
  LATE_TASKS_REPORT: `/reports/${REPORT_PATHS.LATE_TASKS}`,
  TIMELINE_REPORT: `/reports/${REPORT_PATHS.TIMELINE}`,
  TIME_TRACKING_REPORT: `/reports/${REPORT_PATHS.TIME_TRACKING}`,
  KEY_MILESTONES: `/reports/${REPORT_PATHS.KEY_MILESTONES}`,
  USAGE_REPORT: `/reports/${REPORT_PATHS.USAGE}`,
  SWITCH_ORGANIZATION: '/switch-organization',
  NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
  ERROR: '/error',
  FORBIDDEN: '/forbidden',
  INVITATIONS: '/invitations',
  NOTIFICATIONS: '/notifications',
  GOOGLE_CALENDAR_AUTHORIZE: '/google-calendar',
  GOOGLE_EMAIL_AUTHORIZE: '/google-email',
  MICROSOFT_EMAIL_AUTHORIZE: '/microsoft-email',
  MICROSOFT_CALENDAR_AUTHORIZE: '/microsoft-calendar',
}
